import MyRoutes from './route/Route';
import './assets/style/App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import TokenExpiredModal from "./components/Modals/TokenExpiredModal";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import "react-phone-input-2/lib/style.css";
function App() {
  return (
    <>
      <ToastContainer autoClose={1000} closeOnClick={true} />
      <MyRoutes />
      <TokenExpiredModal />
    </>
  );
}

export default App;
