import React, { useState, useEffect } from "react";
import exp from "../../assets/images/exp.png"
// import { FcExpired } from "react-icons/fc";

function TokenExpiredModal() {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const showHandler = () => {
            setShowModal(true);
        };
        document.addEventListener("showTokenExpiredModal", showHandler);
        return () => {
            document.removeEventListener("showTokenExpiredModal", showHandler);
        };
    }, []);

    const handleLogout = () => {
        localStorage.clear()
        window.location.reload()
    };

    return (
        <div style={{ display: showModal ? "flex" : "none", width: "100%", justifyContent: "center" }} className='thankyou'>
            <div className='thankyou_inner'>
                <img className='thankyou_time' src={exp} alt='' />
                <p className='thankyou_thanks'>Your Session has expired, kindly login again.</p>
                <div className='thankyou_btn'>
                    <button className='thankyou_btnExp' onClick={handleLogout}>Login</button>
                </div>
            </div>
        </div>
    );
}

export default TokenExpiredModal;
